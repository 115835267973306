<template>
  <div class="content">
    <div class="a">
      <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="车辆进出记录" :fetch="createExportData" :before-finish="finishDownload" worksheet="车辆进出记录">
          <el-button type="primary" class="el-icon-download"> 导出</el-button>
      </JsonExcel>
      <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
    </div>


    <el-card style="margin-top:15px;width:100%" class="box-card">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="6" :lg="4">
              <div class="search">
                <label>车牌号：</label>
                <el-input v-model="searchForm.plateno" class="marg" style="width:140px"></el-input>
              </div>
            </el-col>
            <el-col :xs="24" :md="12" :lg="6">
              <div class="a">
                <el-select v-model="searchForm.time_type" style="width:150px;margin-right:5px">
                    <el-option label="出场时间" :value="0"></el-option>
                    <el-option label="入场时间" :value="1"></el-option>
                </el-select>
                 <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4">
              <div class="search">
                <label>卡类：</label>
                <el-select v-model="searchForm.card_id" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in cardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4">
              <div class="search">
                <label>车型：</label>
                <el-select v-model="searchForm.car_type" style="width:150px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="小车" :value="1"></el-option>
                  <el-option label="大车" :value="2"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4">
              <div v-if="User.user_type!==5" class="search">
                <label>车场：</label>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                  <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                  <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="2">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
        </el-row>
    </el-card>

        <el-table :data='DataList' border style="width: 100%;font-size:13px"  max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini" @expand-change="expandChanged">
          <el-table-column type="expand" fixed>
            <template slot-scope="slot">
              <el-row :gutter="10">
                <el-col :span="12">
                  <div>
                    <span style="margin-left:30px;font-size:15px">入场图片：</span>
                    <el-image v-if="slot.row.picdata.length>0" style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  :src='slot.row.picdata[0]' fit="fill" @click="ImgClick(0)"></el-image>
                    <el-dialog title="入场图片：" :visible.sync="inImgDialogVisible" :append-to-body='true'>
                        <img width="100%" :src="slot.row.picdata[0]" alt="">
                    </el-dialog>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <span style="margin-left:30px;font-size:15px">出场图片：</span>
                    <el-image v-if="slot.row.picdata.length>0" style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  :src='slot.row.picdata[1]' fit="fill" @click="ImgClick(1)"></el-image>
                    <el-dialog title="出场图片：" :visible.sync="outImgDialogVisible" :append-to-body='true'>
                        <img width="100%" :src="slot.row.picdata[1]" alt="">
                    </el-dialog>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场" min-width="82px" fixed></el-table-column>
          <el-table-column align="center" prop="log_plateno" label="车牌号" min-width="90px" fixed></el-table-column>
          <el-table-column align="center" prop="owner_name" label="姓名"></el-table-column>
          <el-table-column align="center" prop="card_name" label="卡型"></el-table-column>
          <el-table-column align="center" prop="log_car_type" label="车型">
            <template slot-scope="scope">
              {{scope.row.log_car_type===0?'小车':'大车'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="pk_name" label="车场"></el-table-column>

          <el-table-column align="center" label="进出场信息">
            <el-table-column align="center" prop="log_in_time" label="入场时间" min-width="145px">
              <template slot-scope="scope">
                {{FormData(scope.row.log_in_time)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="in_gate_name" label="入场通道"></el-table-column>
            <el-table-column align="center" prop="in_post_name" label="入场岗亭"></el-table-column>
            <el-table-column align="center" prop="in_user_name" label="入场操作员"></el-table-column>
            <el-table-column align="center" prop="log_out_time" label="出场时间" min-width="145px">
              <template slot-scope="scope">
                {{FormData(scope.row.log_out_time)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="out_gate_name" label="出场通道"></el-table-column>
            <el-table-column align="center" prop="out_post_name" label="出场岗亭"></el-table-column>
            <el-table-column align="center" prop="out_user_name" label="出场操作员"></el-table-column>
            <el-table-column align="center" prop="log_time_len" label="停车时长" min-width="100px"></el-table-column>
          </el-table-column>

          <el-table-column align="center" label="缴费信息">
            <el-table-column align="center" prop="log_should_charge" label="应收(元)">
              <template slot-scope="scope">
                {{toDecimal2(scope.row.log_should_charge)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="log_charge" label="实收(元)">
              <template slot-scope="scope">
                {{toDecimal2(scope.row.log_charge)}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="场内预付">
              <el-table-column align="center" prop="center_charge_time" label="预付时间">
                <template slot-scope="scope">
                  {{FormData(scope.row.center_charge_time)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="center_charge" label="预付金额(元)">
                <template slot-scope="scope">
                  {{toDecimal2(scope.row.center_charge)}}
                </template>
              </el-table-column>
            </el-table-column>

          </el-table-column>
          <el-table-column align="center" prop="log_card_status" label="卡类改变原因">
             <template slot-scope="scope">
              {{ChangeCardTypeReason(scope.row.log_card_status)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="log_out_remark" label="出场备注"></el-table-column>
          <el-table-column align="center" prop="log_state" label="支付方式">
             <template slot-scope="scope">
              {{CheckLogState(scope.row)}}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize" @size-change="handleSizeChange"></el-pagination>

  </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

export default {
  data: function () {
    return {
      User:null,
      searchForm: {
        plateno: '',
        time_type: 0,
        pk_id:0,
        time: [],
        pay_type:0,
        card_id: 0,
        car_type: 0,
        pageSize: 15,
        pageNo: 1,
        total: 0
      },
      ParkDataList:[],
      CardTypeDataList: [],
      DataList: [],

      inImgDialogVisible: false,
      outImgDialogVisible:false,

       // 导出
       exportDataStandard: {
        所属车场: "park_name" ,
        车牌号: "log_plateno" ,
        姓名: "owner_name" ,
        卡型: "card_name" ,
        车型: {
          field: 'log_car_type',
          callback: value => {
            return value===0?'小车':'大车'
          }
        },
        车场: "pk_name" ,
        入场时间: {
          field: 'log_in_time',
          callback: value => {
            return this.FormData(value)
          }
        },
        入场通道: "in_gate_name" ,
        入场岗亭: "in_post_name" ,
        入场操作员: "in_user_name" ,
        出场时间:  {
          field: 'log_out_time',
          callback: value => {
            return this.FormData(value)
          }
        },
        出场通道: "out_gate_name" ,
        出场岗亭: "out_post_name" ,
        出场操作员: "out_user_name" ,
        停车时长: "log_time_len" ,
        应收: "log_should_charge" ,
        实收: "log_charge" ,
        场内预付时间:{
          field: 'center_charge_time',
          callback: value => {
               return this.FormData(value)
          }
        },
        预付金额:'center_charge',
        卡类改变原因:{
          field: 'log_card_status',
          callback: value => {
            return this.ChangeCardTypeReason(value)
          }
        },
        支付方式: {
          field: 'log_state',
          callback: value => {
            return this.CheckLogState(value)
          }
        }
      },
      exportData: []


    }
  },
  computed: {
    cardDataList(){
      if(this.User.user_type===5){
          return this.$store.state.CardDataList
        }
      else{
        return this.CardTypeDataList
      }
    },
    toDecimal2(){
        return function(num){
          if(num===null){num=0}
          return util.toDecimal2(num)
        }
    },
    FormData () {
      return function (time) {
        if(time===null||time===undefined||time==='')
          return ''
        else
          return util.FormateDate(time, 2)
      }
    },
    ChangeCardTypeReason () {
      return function (t) {
        if (t === -1) {
          return '一位多车，按临时车进'
        } else if (t === -2) {
          return '已过期，按临时车进'
        } else { return '' }
      }
    },
    CheckLogState () {
      return function (row) {
        if (row.log_state === 0 && (row.log_charge === 0 || row.log_charge === null) && (row.center_charge === 0 || row.center_charge === null)) {
          return '自动放行出场'
        }
        if (row.log_state === 0 && row.log_charge > 0) {
          return '现金支付'
        }
        if (row.log_state === 0 && (row.log_charge === null || row.log_charge === 0) && row.center_charge > 0) {
          return '场内预付'
        }
        if (row.log_state === 1) {
          if(row.log_charge===null){
            return '异常出场'
          }
          if(row.log_charge>0){
            return '超时未收费'
          }
          
        }
        if (row.log_state === 2) {
          return '脱机记录'
        }
        if (row.log_state === 3) {
          return 'app支付'
        }
        if (row.log_state === 4 && row.log_charge > 0) {
          return '未支付'
        }
        if (row.log_state === 0 && row.log_charge === 0 && (row.log_discount_type > 0 || row.md_dis_type > 0)) {
          return '优惠减免放行'
        }
        if (row.log_state === 5) {
            return '免费放行'
        }
        if (row.log_state === 8) {
            return 'etc付费'
        }
      }
    }
  },
  components: {
    JsonExcel
  },
  created () {
    this.User = util.JesToken()
    this.getParkDataList()
  },
  methods: {
    getParkDataList(){
        if(this.User.user_type===5){
          if(this.$store.state.CardDataList.length===0){
              this.$store.dispatch('getCardDataList')
            }
            this.GetDataList()
        }
        else{
          util.Get('parkmanage/getparkdatalist').then(res => {
            if (res.rpStatus === 10000) {
               this.ParkDataList = res.list
               if(res.list.length>0){
                 this.searchForm.pk_id=res.list[0].pk_id
                 this.getCardDataList()
                 this.GetDataList()
               }
            }
          })
        }
    },
    getCardDataList(){
      util.Get('card/getcarddatalist?id='+this.searchForm.pk_id).then(res => {
          if (res.rpStatus === 10000) {
            this.CardTypeDataList = res.list.filter(a => a.card_limit!==8)
          }
      })
    },
    parkChanged(value){
      this.searchForm.pk_id=value
      this.getCardDataList()
      this.searchForm.card_id=0
      this.GetDataList()
    },

    GetDataList () {
      this.searchForm.begintime=''
      this.searchForm.endtime=''
      if(this.searchForm.time&&this.searchForm.time.length>0){
        this.searchForm.begintime=this.searchForm.time[0]
        this.searchForm.endtime=this.searchForm.time[1]
      }
      util.Get('exitlog/getexitlogdataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          if (res.list.length > 0) {
            res.list.forEach(a => {
              a.picdata = []
            })
          }
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search () {
      if(util.CheckUserAuth('4-2-1')){
          this.searchForm.pageNo=1
          this.searchForm.total=0
          this.GetDataList()
        }
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    handleSizeChange(pageSize){
      this.searchForm.pageSize=pageSize
      this.GetDataList()
    },
    expandChanged (row, expandedRows) {
      var arr = expandedRows.filter(a => a.log_id === row.log_id)
      if (arr.length > 0) {
        // 展开行
        if (row.picdata.length === 0 && ((row.log_in_picfullname !== null && row.log_in_picfullname !== undefined && row.log_in_picfullname !== '') || (row.log_out_picfullname !== null && row.log_out_picfullname !== undefined && row.log_out_picfullname !== ''))) {
            if(row.picdata.length===0){           
              util.Get('picture/getparkpictrue?parkId='+row.park_id+'&logId='+row.log_id+'&picInName=' + row.log_in_picfullname + '&picOutName=' + row.log_out_picfullname).then(res=>{
                if(res.rpStatus===10000){
                    var data=JSON.parse(res.list[0])
                    var log=row
                    if(row.park_id!==data.parkId||row.log_id!==data.logId){
                      var arr=this.DataList.filter(a=>a.park_id===data.parkId&&a.log_id==data.logId)
                      if(arr.length>0)
                        log=arr[0]
                    }
                    this.$set(log, 'picdata', [data.in_pic,data.out_pic])
                }
                else{
                  this.$message.error(res.rpMsg)
                }
              })
          }
        }
      }
    },

    ImgClick (value) {
      this.inImgDialogVisible=false
      this.outImgDialogVisible=false
      if(value===0)
        this.inImgDialogVisible = true
      else
        this.outImgDialogVisible=true
    },
    
    
    refreshUpload(){
      util.Send('refresh_upload_outpark',this.searchForm.pk_id)
    },
    // 导出
    async createExportData () {
      if(util.CheckUserAuth('4-2-2')){
        this.searchForm.pageNo = 0
        var form = JSON.parse(JSON.stringify(this.searchForm))
        form.pageNo = 0

        var result = await util.Get('exitlog/getexitlogdataList', form)
        if (result.rpStatus === 10000) {
          this.exportData = result.list
          return this.exportData
        }
      }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
      this.exportData=[]
    }
  }
}
</script>

<style scoped>
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.a{
  display:flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
    /* max-height: calc(100vh - 300px);
    height: calc(100vh - 250px); */
}

::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}

</style>
